(function () {
  'use strict';

  function ContactForm(id) {
    this.$el = $(id);
    if (this.$el.length < 1) {
      return;
    }

    var prefix = 'contact';
    this.$el.on('submit', this.onSubmit.bind(this));
    this.$content = this.$el.find('.' + prefix + '-content');
    this.$confirmation = this.$el.find('.' + prefix + '-confirmation');
    this.$error = this.$el.find('.' + prefix + '-error');
    this.$el.find('input').eq(0).focus();
  }

  ContactForm.prototype.onSubmit = function(evt) {
    function blur() {
      $(evt.target).find('[type="submit"]').blur();
    }

    $.post(this.$el.attr('action'), this.$el.serialize()).then(function (data) {
      var ticket = data ? '(Ticket #' + data + ')' : '';
      blur();
      this.$error.hide();
      this.$el.find(':input').prop('disabled', true);
      this.$content.find('[type=submit]').css('visibility', 'hidden');
      this.$content.css('opacity', '.5');
      this.$confirmation.show();
      this.$confirmation.find('.ticket').text(ticket).css('opacity', '.7');
    }.bind(this), function(res) {
      blur();
      this.$error.show();
      this.$error.find('.status').text(res.status);
      this.$error.find('.error').text(res.responseText);
    }.bind(this));

    return false;
  }

  function rebox() {
    $('.lightbox-thumbs').rebox({selector: 'figure a'});
  }

  function preventCollapse() {
    $('.panel-collapse').on('hide.bs.collapse', function (evt) {
      // $screen-sm-min
      if (window.matchMedia("(min-width: 768px)").matches) {
        evt.preventDefault();
      }
    })
  }

  function syncLanguagesCollapse() {
    var $languages = $('.languages')
    $('.header-nav-content').on('hide.bs.collapse', function (evt) {
      $languages.removeClass('collapse in')
    })
    $('.header-nav-content').on('show.bs.collapse', function (evt) {
      $languages.addClass('collapse in')
    })
  }

  new ContactForm('#contact-form');
  new ContactForm('#contact-book-form');

  rebox();
  preventCollapse();
  syncLanguagesCollapse();
})();

$(document).ready(function () {
    $('.header-nav-sub li a').click(function () {
        $('.header-nav-content').collapse('hide')
    })
})

$(document).scroll(function () {
  var scroll = $(this).scrollTop()
  if (scroll > 0) {
    $('.header').addClass('fixed')
  } else {
    $('.header').removeClass('fixed')
  };
})